import type { ValueObject } from '~/backend/value-objects/shared/ValueObject';
import * as z from 'zod';

export const voPlayerGoalSchema = z.coerce
	.number()
	.min(0)
	.max(999999999)
	.refine((arg) => arg.toFixed(2));

class PlayerGoalNumber extends Number {
	constructor(value: number = 0) {
		value = voPlayerGoalSchema.parse(value);

		super(value);
	}

	get hasGoal() {
		return this.valueOf() > 0;
	}
}

export type VoPlayerGoal = ValueObject<PlayerGoalNumber>;

export function newVoPlayerGoal(value: number = 0): VoPlayerGoal {
	return new PlayerGoalNumber(value);
}
