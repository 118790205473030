import type { Game } from '~/server/database/schema';
import type { UseFetchOptions } from '#app';

export function useGameBySlug(gameSlug: string) {
	return useFetch(`/api/game/by-slug/${gameSlug}`);
}

export function useGamesByOrg(orgId: Ref<string>, opts?: UseFetchOptions<Game[]>) {
	return useFetch<Game[]>(() => `/api/games/byOrg/${orgId.value}`, opts);
}

export function useGameSlug() {
	const route = useRoute();
	return route.params.gameSlug;
}

export function useActiveGames() {
	return useState<Game[]>('games', () => []);
}

export function useSafeActiveGame() {
	return useState<Game | null>('game::active');
}

export function useActiveGame() {
	const activeGame = useState<Game>('game::active');
	if (!activeGame || !activeGame.value) {
		throw new Error('No active game found');
	}
	return activeGame;
}
