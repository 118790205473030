import type { ValueObject } from '~/backend/value-objects/shared/ValueObject';
import * as z from 'zod';

export const voPlayerPointsSchema = z.coerce
	.number()
	.min(0)
	.max(999999999)
	.refine((arg) => arg.toFixed(2));

export type VoPlayerPoints = ValueObject<number>;

export function newVoPlayerPoints(value: number = 0): VoPlayerPoints {
	return voPlayerPointsSchema.parse(value);
}
