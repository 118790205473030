import * as z from 'zod';
import type { ValueObject } from './shared/ValueObject';

export type VoTitle = ValueObject<string>;

export const voTitleSchema = z.coerce.string().trim().min(1).max(50);

export function newVoTitle(value: string): VoTitle {
	return voTitleSchema.parse(value);
}
