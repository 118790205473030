import { z } from 'zod';
import slugify from 'slugify';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import { blob, index, integer, numeric, primaryKey, real, sqliteTable, text, unique } from 'drizzle-orm/sqlite-core';
import { voIdSchema } from '../../backend/value-objects/VoId';
import { voPathSchema } from '../../backend/value-objects/VoPath';
import { voTitleSchema } from '../../backend/value-objects/VoTitle';
import { voDescriptionSchema } from '../../backend/value-objects/VoDescription';
import { voPlayerGoalSchema } from '../../backend/entities/player/value-objects/VoPlayerGoal';
import { voPlayerPointsSchema } from '../../backend/entities/player/value-objects/VoPlayerPoints';
import { voPlayerPointsUpdatedAtSchema } from '../../backend/entities/player/value-objects/VoPlayerPointsUpdatedAt';
import { voNameSchema } from '~/backend/value-objects/VoName';

export const authUser = sqliteTable('auth_user', {
	id: text('id').primaryKey(),
	email: text('email', { length: 254 }).notNull().unique(),
	name: text('name', { length: 100 }).notNull(),
});

export type AuthUser = typeof user.$inferSelect;

export const insertAuthUserSchema = createInsertSchema(authUser, {
	email: (schema) => schema.email(),
});

export const user = sqliteTable('user', {
	id: text('id').primaryKey(),

	email: text('email', { length: 254 }).notNull().unique(),
	password: text('password', { length: 254 }).notNull(),

	name: text('name', { length: 100 }).notNull(),
	avatar: text('avatar', { length: 254 }),
});

export type User = typeof user.$inferSelect;

export const insertUserSchema = createInsertSchema(user, {
	email: (schema) => schema.email(),
	avatar: (schema) => schema.url(),
});

export const session = sqliteTable('user_session', {
	id: text('id').primaryKey(),
	userId: text('user_id')
		.notNull()
		.references(() => authUser.id),
	activeExpires: integer('active_expires', { mode: 'number' }).notNull(),
	idleExpires: integer('idle_expires', { mode: 'number' }).notNull(),
});

export const key = sqliteTable('user_key', {
	id: text('id').primaryKey(),
	userId: text('user_id', { length: 15 })
		.notNull()
		.references(() => authUser.id),
	hashedPassword: text('hashed_password'),
});

export const org = sqliteTable('org', {
	id: text('id').primaryKey(),
	name: text('name', { length: 100 }).notNull(),
	slug: text('slug').notNull().unique(),
	email: text('email', { length: 254 }).notNull(),

	limitGames: integer('limit_games').notNull(),
	validity: integer('validity', { mode: 'timestamp_ms' }).notNull(),
});
export const organization = org;

export type Org = typeof org.$inferSelect;
export type Organization = Org;

export const insertOrgSchema = createInsertSchema(org, {
	email: (schema) => schema.email(),
	slug: (schema) =>
		schema.transform((arg, _ctx) =>
			slugify(arg, {
				lower: true,
				strict: true,
			}),
		),
});

export const OrgMemberRoleArray = ['owner', 'admin', 'member', 'readOnly'] as const;

export const orgMember = sqliteTable(
	'org_member',
	{
		userId: text('user_id')
			.notNull()
			.references(() => user.id),
		orgId: text('org_id')
			.notNull()
			.references(() => org.id),

		role: text('role', {
			enum: OrgMemberRoleArray,
		}).notNull(),
	},
	(table) => {
		return {
			// pk: primaryKey({ columns: [table.userId, table.orgId] }),
			unqUserPerOrg: unique().on(table.orgId, table.userId), // NOTE: temporario enquanto um user puder ser de apenas uma org
		};
	},
);

export type OrgMember = typeof orgMember.$inferSelect;

export const insertOrgMemberSchema = createInsertSchema(orgMember);

export const orgInvitation = sqliteTable(
	'org_invitation',
	{
		orgId: text('org_id')
			.notNull()
			.references(() => org.id),

		email: text('email', { length: 254 }).notNull(),
		role: text('role', {
			enum: OrgMemberRoleArray,
		}).notNull(),
		autoAccept: numeric('auto_accept').notNull().default('0.0'),
	},
	(table) => {
		return {
			pk: primaryKey({ columns: [table.email, table.orgId] }),
		};
	},
);

export type OrgInvitation = typeof orgInvitation.$inferSelect;

export const insertOrgInvitationSchema = createInsertSchema(orgInvitation, {
	email: (schema) => schema.email(),
});

export const product = sqliteTable('product', {
	id: text('id').primaryKey(),
	name: text('name').notNull(),
	code: text('code').notNull(),
	monthlyPrice: integer('pricing_monthly', { mode: 'number' }).notNull(),
	stripeMonthlyPriceId: text('stripe_monthly_pricing_id').notNull(),
	yearlyPrice: integer('pricing_yearly', { mode: 'number' }).notNull(),
	stripeYearlyPriceId: text('stripe_yearly_pricing_id').notNull(),
	features: blob('features').notNull(),
});

export type Product = typeof product.$inferSelect;

export const insertProductSchema = createInsertSchema(product);

export const stripeCustomer = sqliteTable('stripe_customer', {
	id: text('id').notNull().primaryKey(),
	email: text('email').notNull(),
	orgId: text('org_id')
		.notNull()
		.references(() => org.id),
});

export const subscription = sqliteTable('stripe_subscription', {
	stripeSubscriptionId: text('stripe_subscription_id').notNull().primaryKey(),
	orgId: text('org_id')
		.notNull()
		.references(() => org.id),
	name: text('name').notNull(),
	code: text('code').notNull(),
	interval: text('interval').default('month').notNull(),
	stripeCustomerId: text('stripe_customer_id').notNull(),
	expires: integer('expires', { mode: 'number' }).notNull(),
});

export const game = sqliteTable(
	'games',
	{
		id: text('id').notNull().primaryKey(),

		orgId: text('org_id')
			.notNull()
			.references(() => org.id),

		name: text('name', { length: 100 }).notNull(),
		slug: text('slug').notNull(),
	},
	(table) => {
		return {
			unqSlugPerOrg: unique().on(table.orgId, table.slug),
		};
	},
);

export type Game = typeof game.$inferSelect;
export type SelectGame = typeof game.$inferSelect;
export type InsertGame = typeof game.$inferInsert;

export const insertGameSchema = createInsertSchema(game, {
	id: (_schema) => voIdSchema,
	orgId: (_schema) => voIdSchema,
	name: (_schema) => voNameSchema,
});

export const player = sqliteTable(
	'players',
	{
		id: text('id').notNull().primaryKey(),

		userId: text('user_id')
			.notNull()
			.references(() => user.id),

		gameId: text('game_id')
			.notNull()
			.references(() => game.id),

		orgId: text('org_id')
			.notNull()
			.references(() => org.id),

		goal: real('goal').notNull(),
		points: real('points').notNull(),
		pointsUpdatedAt: integer('points_updated_at', { mode: 'timestamp_ms' }).notNull(),
	},
	(table) => {
		return {
			unqUserPerGame: unique().on(table.userId, table.gameId),
			unqPointsPerTime: unique().on(table.points, table.pointsUpdatedAt),

			idxPlayersAround: index('players_around_idx').on(table.id, table.gameId, table.points, table.pointsUpdatedAt),
			idxLeaderboardOrdering: index('leaderboard_ordering_idx').on(table.points, table.pointsUpdatedAt),
			// pk: primaryKey({ columns: [table.userId, table.gameId] }),
		};
	},
);

export type Player = typeof player.$inferSelect;

export const insertPlayerSchema = createInsertSchema(player, {
	id: (_schema) => voIdSchema,
	userId: (_schema) => insertUserSchema.shape.id,

	orgId: (_schema) => insertOrgSchema.shape.id,
	gameId: (_schema) => insertGameSchema.shape.id,

	goal: (_schema) => voPlayerGoalSchema,
	points: (_schema) => voPlayerPointsSchema,
	pointsUpdatedAt: (_schema) => voPlayerPointsUpdatedAtSchema,
});

export enum AwardTypes {
	FirstPlace = 'FirstPlace',
	SecondPlace = 'SecondPlace',
	ThirdPlace = 'ThirdPlace',
}

export const awardTypeSchema = z.nativeEnum(AwardTypes);
export type AwardTypeEnum = z.infer<typeof awardTypeSchema>;

export const awardTable = sqliteTable(
	'awards',
	{
		id: text('id').primaryKey(),
		organizationId: text('organization_id')
			.notNull()
			.references(() => organization.id, { onDelete: 'cascade' }),
		gameId: text('game_id')
			.notNull()
			.references(() => game.id, { onDelete: 'cascade' }),

		title: text('title', { length: 50 }).notNull(),
		description: text('description', { length: 255 }),
		avatar: text('avatar', { length: 255 }),

		type: text('type', {
			enum: Object.values(AwardTypes) as [string, ...string[]],
		})
			.notNull()
			.$type<AwardTypeEnum>(),
	},
	(table) => {
		return {
			unq: unique().on(table.gameId, table.type),
		};
	},
);
export const award = awardTable; // alias

export const awardInsertSchema = createInsertSchema(awardTable, {
	id: (_schema) => voIdSchema,
	organizationId: (_schema) => voIdSchema,
	gameId: (_schema) => voIdSchema,

	title: (_schema) => voTitleSchema,
	description: (_schema) => voDescriptionSchema.optional(),
	avatar: (_schema) => voPathSchema.optional(),

	type: (_schema) => awardTypeSchema,
});
export type AwardInsertSchema = z.infer<typeof awardInsertSchema>;

export const awardSelectSchema = createSelectSchema(awardTable, {
	id: (_schema) => voIdSchema,
	organizationId: (_schema) => voIdSchema,
	gameId: (_schema) => voIdSchema,

	title: (_schema) => voTitleSchema,
	description: (_schema) => voDescriptionSchema.optional(),
	avatar: (_schema) => voPathSchema.optional(),

	type: (_schema) => awardTypeSchema,
});
export type AwardSelectDto = z.infer<typeof awardSelectSchema>;
// export type Award = AwardSelectSchema; // alias

// NOTE: nao precisa de timestamp porque isso sera registrado no analitics
// NOTE: evitar o maximo de redundancia, porque a consulta e mais barata que o armazenamento
