import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/opt/buildhome/repo/middleware/01.auth.global.ts";
import _02_45org_45global from "/opt/buildhome/repo/middleware/02.org.global.ts";
import _03_45game_45global from "/opt/buildhome/repo/middleware/03.game.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45org_45global,
  _03_45game_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "select-some-game": () => import("/opt/buildhome/repo/middleware/selectSomeGame.ts"),
  "select-some-org": () => import("/opt/buildhome/repo/middleware/selectSomeOrg.ts")
}