import type { ValueObject } from '~/backend/value-objects/shared/ValueObject';
import { insertUserSchema } from '~/server/database/schema';

export const voEmailSchema = insertUserSchema.shape.email;

export type VoEmail = ValueObject<string>;

export function newVoEmail(value: string): VoEmail {
	const email = voEmailSchema.parse(value);
	return email;
}
