import type { ValueObject } from './shared/ValueObject';
import * as z from 'zod';

export type VoId = ValueObject<string>;

export const voIdSchema = z.coerce.string().trim().max(100);

export function newVoId(value: unknown): VoId {
	const voId = voIdSchema.parse(value);
	return voId;
}
