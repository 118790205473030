import * as z from 'zod';
import type { ValueObject } from './shared/ValueObject';

export type VoPath = ValueObject<string>;

export const voPathSchema = z.coerce.string().trim().min(1).max(255);

export function newVoPath(value: string): VoPath {
	return voPathSchema.parse(value);
}
