import * as z from 'zod';
import type { ValueObject } from './shared/ValueObject';

export type VoName = ValueObject<string>;

export const voNameSchema = z.coerce.string().trim().min(1).max(50);

export function newVoName(value: string): VoName {
	return voNameSchema.parse(value);
}
