import { useActiveGames } from '~/composables/game';

// responsible by load and set active game by slug
export default defineNuxtRouteMiddleware((to) => {
	const toGameSlug = to.params.gameSlug;
	if (toGameSlug === 'organization-settings') return;

	const activeGame = useSafeActiveGame();
	if (!toGameSlug || Array.isArray(toGameSlug)) {
		activeGame.value = null;
		return;
	}

	const activeGames = useActiveGames();
	if (!activeGames.value || activeGames.value.length === 0) {
		activeGame.value = null;
		return;
	}

	const fromGameSlug = activeGame.value?.slug;
	const changeGame = toGameSlug !== fromGameSlug;

	if (!activeGame.value || changeGame) {
		const game = activeGames.value?.find((g) => g.slug === toGameSlug);
		if (game) {
			activeGame.value = game;
		} else {
			activeGame.value = null;
		}
	}
});
