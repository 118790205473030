import { newVoId, type VoId } from '~/backend/value-objects/VoId';
import { dbGetFirstOrgMemberByUserOrgs } from '../db/orgMember/dbGetAdminInSome';
import type { Session } from '~/server/utils/session';
import { newVoEmail, type VoEmail } from '../value-objects/VoEmail';
import { newVoName, type VoName } from '../value-objects/VoName';
import {
	dbGetAllOrgMembersForSessionByUserId,
	type OrgMemberForSession,
} from '~/backend/db/orgMember/dbGetAllOrgMembersForSessionByUserId';

export type PermissionGuardSchema = {
	userId: string;
	userName: string;
	userEmail: string;

	userPermissions: OrgMemberForSession[];
};

export type OrgMemberOwnerRole = 'owner';
export type OrgMemberAdminRole = OrgMemberOwnerRole | 'admin';
export type OrgMemberMemberRole = OrgMemberAdminRole | 'member';
export type OrgMemberReadOnlyRole = OrgMemberMemberRole | 'readOnly';
export type OrgMemberRole = OrgMemberReadOnlyRole;

export function orgMemberRoleGuard(value: string): value is OrgMemberRole {
	switch (value) {
		case 'owner':
			return true;
		case 'admin':
			return true;
		case 'member':
			return true;
		case 'readOnly':
			return true;
		default:
			return false;
	}
}

export const orgMemberRolePositions = ['readOnly', 'member', 'admin', 'owner'] as const;

export const orgMemberRole = Object.freeze({
	owner: 'owner',
	admin: 'admin',
	member: 'member',
	readOnly: 'readOnly',
});

export function isOrgOwner(role: OrgMemberRole) {
	return role === orgMemberRole.owner;
}

export function isOrgAdmin(role: OrgMemberRole) {
	return isOrgOwner(role) || role === orgMemberRole.admin;
}

export function isOrgMember(role: OrgMemberRole) {
	return isOrgAdmin(role) || role === orgMemberRole.member;
}

export function isOrgReadOnly(role: OrgMemberRole) {
	return isOrgMember(role) || role === orgMemberRole.readOnly;
}

export class PermissionGuard implements PermissionGuardSchema {
	private readonly _userId: VoId;
	private readonly _userName: VoName;
	private readonly _userEmail: VoEmail;

	private readonly _userPermissions: OrgMemberForSession[];

	constructor(params: PermissionGuardSchema) {
		this._userId = newVoId(params.userId);
		this._userName = newVoName(params.userName);
		this._userEmail = newVoEmail(params.userEmail);

		this._userPermissions = params.userPermissions;
	}

	static async safeCreateBySession(session: Session) {
		const orgMembers = await dbGetAllOrgMembersForSessionByUserId(session.data.user.id);
		return new PermissionGuard({
			userId: session.data.user.id,
			userName: session.data.user.name,
			userEmail: session.data.user.email,

			userPermissions: orgMembers,
		});
	}

	static async createBySession(session: Session) {
		const permissionGuard = await PermissionGuard.safeCreateBySession(session);
		if (!permissionGuard) {
			throw createError({
				statusCode: 401,
				statusMessage: 'Unauthorized',
			});
		}
		return permissionGuard;
	}

	get userId() {
		return this._userId;
	}

	get userName() {
		return this._userName;
	}

	get userEmail() {
		return this._userEmail;
	}

	get userPermissions() {
		return this._userPermissions;
	}

	get raw(): PermissionGuardSchema {
		return {
			userId: this._userId,
			userName: this._userName,
			userEmail: this._userEmail,

			userPermissions: this._userPermissions,
		};
	}

	private getOwner(orgId: VoId) {
		const findOrgMember = (orgMember: OrgMemberForSession) => orgMember.orgId === orgId && isOrgOwner(orgMember.role);

		const permission = this._userPermissions.find(findOrgMember);
		return permission;
	}

	private getAdmin(orgId: VoId) {
		const findOrgMember = (orgMember: OrgMemberForSession) => orgMember.orgId === orgId && isOrgAdmin(orgMember.role);

		const permission = this._userPermissions.find(findOrgMember);
		return permission;
	}

	private getMember(orgId: VoId) {
		const findOrgMember = (orgMember: OrgMemberForSession) => orgMember.orgId === orgId && isOrgMember(orgMember.role);

		const permission = this._userPermissions.find(findOrgMember);
		return permission;
	}

	private getReadOnly(orgId: VoId) {
		const findOrgMember = (orgMember: OrgMemberForSession) => orgMember.orgId === orgId && isOrgReadOnly(orgMember.role);

		const permission = this._userPermissions.find(findOrgMember);
		return permission;
	}

	mustBeOwner(voOrganizationId: VoId) {
		const permission = this.getOwner(voOrganizationId);
		if (!permission) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return permission;
	}

	isOwner(orgId: VoId) {
		return this.mustBeOwner(orgId);
	} // alias

	mustBeAdmin(voOrganizationId: VoId) {
		const permission = this.getAdmin(voOrganizationId);
		if (!permission) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return permission;
	}

	isAdmin(voOrganizationId: VoId) {
		return this.mustBeAdmin(voOrganizationId);
	} // alias

	isMember(orgId: VoId) {
		const permission = this.getMember(orgId);
		if (!permission) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return permission;
	}

	isReadOnly(orgId: VoId) {
		const permission = this.getReadOnly(orgId);
		if (!permission) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return permission;
	}

	safeIsPermissionHigher(orgId: VoId, orgMemberRole: OrgMemberRole) {
		const permission = this.getReadOnly(orgId);
		if (!permission) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}

		const orgMemberRolePositionA = orgMemberRolePositions.findIndex((role) => role === permission.role);
		const orgMemberRolePositionB = orgMemberRolePositions.findIndex((role) => role === orgMemberRole);

		return orgMemberRolePositionA > orgMemberRolePositionB;
	}

	safeIsPermissionLower(orgId: VoId, orgMemberRole: OrgMemberRole) {
		const permission = this.getReadOnly(orgId);
		if (!permission) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}

		const orgMemberRolePositionA = orgMemberRolePositions.findIndex((role) => role === permission.role);
		const orgMemberRolePositionB = orgMemberRolePositions.findIndex((role) => role === orgMemberRole);

		return orgMemberRolePositionA < orgMemberRolePositionB;
	}

	mustBeValidPermission(orgId: VoId, orgMemberRole: OrgMemberRole) {
		const isPermissionLower = this.safeIsPermissionLower(orgId, orgMemberRole);
		if (isPermissionLower) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return isPermissionLower;
	}

	private safeIsMe(userId: VoId) {
		const isMe = this._userId.toString() === userId.toString();
		return isMe;
	}

	isMe(myUserId: VoId) {
		const isMe = this.safeIsMe(myUserId);
		if (!isMe) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return isMe;
	}

	private async safeIsMyAdmin(targetUserId: VoId) {
		const myOrgAdminIds = this._userPermissions.filter((om) => isOrgAdmin(om.role)).map((om) => om.orgId);
		if (myOrgAdminIds.length === 0) {
			return false;
		}

		const myAdmin = await dbGetFirstOrgMemberByUserOrgs(targetUserId, myOrgAdminIds);
		return myAdmin;
	}

	async isMyAdmin(adminUserId: VoId) {
		const myAdmin = await this.safeIsMyAdmin(adminUserId);
		if (!myAdmin) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return myAdmin;
	}

	async isMeOrMyAdmin(targetUserId: VoId) {
		const isMe = this.safeIsMe(targetUserId);
		const isMyAdmin = await this.safeIsMyAdmin(targetUserId);

		const allowed = isMe || isMyAdmin;
		if (allowed === false) {
			throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
		}
		return { isMe, isMyAdmin };
	}

	private async safeIsMyCoworker(targetUserId: VoId) {
		const myOrgIds = this._userPermissions.map((om) => om.orgId);
		if (myOrgIds.length === 0) {
			return false;
		}

		const coworker = await dbGetFirstOrgMemberByUserOrgs(targetUserId, myOrgIds);
		return coworker;
	}

	async isMyCoworker(targetUserId: VoId) {
		const isMe = this.safeIsMe(targetUserId);
		if (isMe) {
			return { isMe };
		}

		const isCoworker = await this.safeIsMyCoworker(targetUserId);
		if (isCoworker) {
			return { isMe, isCoworker };
		}

		throw createError({ statusCode: 403, statusMessage: 'Forbidden' });
	}
}

export async function safeUsePermissionGuard(session: Session) {
	return PermissionGuard.safeCreateBySession(session);
}

export async function usePermissionGuard(session: Session) {
	return PermissionGuard.createBySession(session);
}
