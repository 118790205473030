import { useActiveGames } from '~/composables/game';

// responsible by load and set active organization by slug
export default defineNuxtRouteMiddleware(async (to) => {
	const toOrgSlug = to.params.orgSlug;

	const activeOrg = useSafeActiveOrg();

	const activeGames = useActiveGames();

	if (!toOrgSlug || Array.isArray(toOrgSlug)) {
		activeOrg.value = null;

		activeGames.value = [];

		return;
	}

	const fromOrgSlug = activeOrg.value?.slug;
	const changeOrg = toOrgSlug !== fromOrgSlug;

	if (!activeOrg.value || changeOrg) {
		activeGames.value = [];

		const { data: organization } = await useOrgBySlug(toOrgSlug);
		if (organization.value) {
			const validity = new Date(organization.value.validity);
			activeOrg.value = {
				...organization.value,

				validity,
			};
		} else {
			activeOrg.value = null;
		}
	}

	const activeOrganizationId = computed(() => activeOrg.value?.id ?? '');

	if (activeOrg.value && changeOrg) {
		const { data: orgGames } = await useGamesByOrg(activeOrganizationId);
		if (orgGames.value) {
			activeGames.value = orgGames.value;
		} else {
			activeGames.value = [];
		}
	}
});
