import * as z from 'zod';
import type { ValueObject } from './shared/ValueObject';

export type VoDescription = ValueObject<string>;

export const voDescriptionSchema = z.coerce.string().trim().min(1).max(255);

export function newVoDescription(value: string): VoDescription {
	return voDescriptionSchema.parse(value);
}
