import type { OrganizationEntitySchema } from '~/backend/entities/organization/OrganizationEntity';

export function useOrgs() {
	return useFetch('/api/me/orgs');
}

export function useOrgSlug() {
	const route = useRoute();
	return route.params.orgSlug;
}

export function useOrgData() {
	const orgSlug = useOrgSlug();
	return useFetch(() => `/api/orgs/${orgSlug}`);
}

export function useOrgBySlug(orgSlug: string) {
	const headers = useRequestHeaders(['cookie']);
	return useFetch(`/api/org/by-slug/${orgSlug}`, { headers });
}

export function useSafeActiveOrg() {
	return useState<OrganizationEntitySchema | null>('org::active');
}

export function useActiveOrg() {
	const refActiveOrg = useSafeActiveOrg();

	return computed(() => {
		const activeOrg = unref(refActiveOrg);
		if (!activeOrg) {
			throw createError('useActiveOrg() can only be used in protected pages');
		}
		return activeOrg;
	});
}
