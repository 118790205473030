export default defineNuxtRouteMiddleware(async (to) => {
	const { data, error } = await useFetch('/api/me');
	if (error.value) {
		throw createError('Failed to fetch data');
	}

	const user = useUser();
	user.value = data.value?.user ? data.value : null;

	if (to.path.startsWith('/app') && !user.value) {
		return navigateTo('/login');
	}
});
