import { orgMemberRole } from '~/backend/utils/PermissionGuard';
import type { OrgMember } from '~/server/database/schema';

type UserSessionDto = {
	id: string;
	userId: string;

	name: string;
	userName: string;

	email: string;
	userEmail: string;

	permissions: OrgMember[];
	userPermissions: OrgMember[];

	user: {
		id: string;
		name: string;
		email: string;
	};
};

export function useUser(): Ref<UserSessionDto | null> {
	const user = useState<UserSessionDto | null>('user', () => null);
	return user;
}

export function useAuthenticatedUser() {
	const refUser = useUser();

	return computed<UserSessionDto>(() => {
		const user = unref(refUser);
		if (!user) {
			throw createError('useAuthenticatedUser() can only be used in protected pages');
		}
		return user;
	});
}
export const useActiveUser = useAuthenticatedUser;

export function useMyPermissions() {
	const activeUser = useAuthenticatedUser();

	const activeOrg = useActiveOrg();

	const myRole = computed(() => {
		const activeUserValue = unref(activeUser);
		if (!activeUserValue) return [];

		const activeOrgValue = unref(activeOrg);
		if (!activeOrgValue) return [];

		return activeUserValue.permissions.find(
			(permission: any) => permission.orgId === activeOrgValue.id && permission.userId === activeUserValue.userId,
		)?.role;
	});

	const isOwner = computed(() => {
		const myRoleValue = unref(myRole);
		return myRoleValue === orgMemberRole.owner;
	});

	const isAdmin = computed(() => {
		const myRoleValue = unref(myRole);

		return myRoleValue === orgMemberRole.owner || myRoleValue === orgMemberRole.admin;
	});

	const isMember = computed(() => {
		const myRoleValue = unref(myRole);

		return myRoleValue === orgMemberRole.owner || myRoleValue === orgMemberRole.admin || myRoleValue === orgMemberRole.member;
	});

	const isReadOnly = computed(() => {
		const myRoleValue = unref(myRole);

		return (
			myRoleValue === orgMemberRole.owner ||
			myRoleValue === orgMemberRole.admin ||
			myRoleValue === orgMemberRole.member ||
			myRoleValue === orgMemberRole.readOnly
		);
	});

	return {
		isOwner,
		isAdmin,
		isMember,
		isReadOnly,
	};
}
