import type { ValueObject } from '~/backend/value-objects/shared/ValueObject';
import * as z from 'zod';

export const voPlayerPointsUpdatedAtSchema = z.date().min(new Date('1900-01-01'), { message: 'Too old' });

export type VoPlayerPointsUpdatedAt = ValueObject<Date>;

export function newVoPlayerPointsUpdatedAt(value: number | string | Date = new Date()): VoPlayerPointsUpdatedAt {
	return voPlayerPointsUpdatedAtSchema.parse(value);
}
